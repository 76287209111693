<template>
  <high-charts-responsive :options="highchartOptions" ref="graph" class="highcharts-container" />
</template>

<script>
/* eslint-disable import/no-named-default */
import moment from 'moment-timezone';

import { default as HighChartsResponsive, timeDependentButtons } from '../../HighChartsResponsive.vue';
import SelectionMixin from '../iv/SelectionMixin';

const colors = ['#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce', '#492970', '#f28f43', '#77a1e5', '#c42525', '#a6c96a'];

export default {
  name: 'ControlComparisonGraph',
  mixins: [SelectionMixin],
  components: {
    HighChartsResponsive
  },
  props: {
    site: Object,
    options: Object
  },
  data() {
    return {
      colorCache: [],
      highchartOptions: {
        chart: {
          zoomType: 'x',
        },
        title: {
          text: '',
        },
        xAxis: {
          type: 'datetime',
          title: {
            text: 'Time'
          },
          showEmpty: false,
        },
        yAxis: {
          title: {
            text: 'Percent Difference'
          },
          labels: {
            format: '{value}%'
          },
          showEmpty: false
        },
        plotOptions: {
          series: {
            states: {
              hover: {
                enabled: false
              },
              inactive: {
                opacity: 1
              }
            }
          }
        },
        series: [],
        tooltip: {
          formatter(tooltip) {
            const value = parseFloat(this.point.y.toFixed(3));
            const date = tooltip.defaultFormatter.call(this, tooltip)[0];
            return `${date}<span style="color: ${this.color}">\u25CF</span> ${this.series.name}<br/>
            <b>${this.series.name}: </b><span style="color: #1cb150; font-weight: bold;">${value} %</span>`;
          },
          useHTML: true
        },
        legend: {
          padding: 20,
          maxHeight: 90
        },
        responsive: {
          rules: [{
            condition: {
              maxWidth: 600
            },
            chartOptions: {
              legend: {
                maxHeight: 55
              }
            }
          }]
        },
        exporting: {
          buttons: {
            contextButton: {
              menuItems: timeDependentButtons
            }
          },
          filename: 'control-percent-diff'
        }
      }
    };
  },
  computed: {
    localOptions() {
      return {
        controlModule: (this.options && this.options.controlModule) || null,
        modules: (this.options && this.options.modules) || [],
        parameter: (this.options && this.options.parameter) || null,
        from: (this.options && this.options.from) || null,
        to: (this.options && this.options.to) || null
      };
    },
    isEmpty() {
      const { controlModule, modules, parameter, from, to } = this.localOptions;
      return !controlModule || !modules.length || !parameter || !from || !to;
    }
  },
  methods: {
    showLoading() {
      if (this.$refs.graph) this.$refs.graph.chart.showLoading();
      this.$emit('loading', true);
    },
    hideLoading() {
      if (this.$refs.graph) this.$refs.graph.chart.hideLoading();
      this.$emit('loading', false);
    },
    async plot() {
      if (this.isEmpty) {
        this.clearPlot();
        return;
      }

      try {
        this.showLoading();
        const { modules, from, to } = this.localOptions;
        const fromIso = moment.tz(moment(from).startOf('day').format('YYYY-MM-DD HH:mm:ss'), this.site.timezone).toISOString(true);
        const toIso = moment.tz(moment(to).endOf('day').format('YYYY-MM-DD HH:mm:ss'), this.site.timezone).toISOString(true);
        const series = await Promise.all(modules.map(module => this.getModuleSeries(module, fromIso, toIso)));
        const seriesWithData = series.filter(s => s && s.data.length);
        if (seriesWithData.length) {
          this.$set(this.highchartOptions, 'series', seriesWithData);
        } else {
          this.$toastWarn('No Data', 'No data is available for the selected duration.');
        }
      } catch (e) {
        if (e.name === 'ApiError') {
          if (e.status === 400 && e.responseBody.details) {
            const module = this.localOptions.modules.find(m => m.uuid === e.request.options.query.moduleUuid);
            const message = `Module ${module.label}: ${e.message}. ${e.responseBody.details.join('. ')}`;
            this.$toastError(`Error ${e.status || ''}`, message);
          } else {
            this.$toastError(`Error ${e.status || ''}`, e.message);
          }
        } else {
          throw e;
        }
      } finally {
        this.hideLoading();
        // Calling redraw redisplays the no data label when the graph is originally empty
        if (this.$refs.graph && this.$refs.graph.chart && !this.highchartOptions.series.length) this.$refs.graph.chart.redraw();
      }
    },
    clearPlot() {
      this.$set(this.highchartOptions, 'series', []);
    },
    async getModuleSeries(module, from, to) {
      const { controlModule: control, parameter } = this.localOptions;
      const response = await this.$daqApi.get(
        `/sites/${this.site.id}/iv/control-percent-diff`,
        { query: { controlModuleUuid: control.uuid, moduleUuid: module.uuid, parameter: parameter.name, from, to, period: 'P1D' } }
      );

      const data = response.map(d => ({ x: (new Date(d.timestamp)).valueOf(), y: d.percentDiff }));
      const id = `${module.uuid}_${parameter.name}`;
      const name = `Module ${module.name}`;
      const color = this.getColor(id);
      const selection = { id, moduleUuid: module.uuid };
      const metaData = { selection };
      return { id, name, color, selection, metaData, data, type: 'line', zIndex: 2 };
    },
    getColor(id) {
      let index = this.colorCache.indexOf(id);
      if (index < 0) index = this.colorCache.push(id) - 1;
      return colors[index % colors.length];
    }
  },
  watch: {
    site: {
      immediate: true,
      handler() {
        if (this.site) this.$set(this.highchartOptions, 'time', { timezone: this.site.timezone });
      }
    },
    localOptions() {
      this.plot();
    }
  }
};
</script>

<style lang="scss" scoped>
.highcharts-container {
  min-height: 0;
  min-width: 0;
}
</style>
