<template>
  <high-charts-responsive :options="highchartOptions" ref="graph" class="highcharts-container" />
</template>

<script>
/* eslint-disable import/no-named-default */
import moment from 'moment-timezone';
import { default as HighChartsResponsive, timeDependentButtons } from '../../HighChartsResponsive.vue';
import { mapToUnit, mapTypeToAxes, mapTypeToAxesData, parseEnergy, extractIVData, extractEnergyData, extractSensorData } from '../helpers';
import IvFilterMixin from './IvFilterMixin';
import SelectionMixin from './SelectionMixin';

const colors = ['#7cb5ec', '#434348', '#90ed7d', '#f7a35c', '#8085e9', '#f15c80', '#e4d354', '#2b908f', '#f45b5b', '#91e8e1'];

export default {
  name: 'IvParametersGraph',
  mixins: [IvFilterMixin, SelectionMixin],
  components: {
    HighChartsResponsive
  },
  props: {
    site: Object,
    options: Object
  },
  data() {
    return {
      colorCache: [],
      highchartOptions: {
        chart: {
          zoomType: 'x'
        },
        title: {
          text: ''
        },
        xAxis: {
          type: 'datetime',
          title: {
            text: 'Time'
          },
          showEmpty: false,
          minRange: 900000
        },
        yAxis: [],
        series: [],
        plotOptions: {
          column: {
            pointWidth: 20
          },
          series: {
            states: {
              hover: {
                enabled: false
              },
              inactive: {
                opacity: 1
              }
            }
          }
        },
        tooltip: {
          formatter: function formatter(tooltip) {
            const { type, unit } = this.series.options.metaData;
            const value = type === 'Operational Energy' || type === 'Isolated Energy' ?
              parseEnergy(this.point.y, 2) : `${parseFloat(this.point.y.toFixed(3))} ${unit}`;
            const date = tooltip.defaultFormatter.call(this, tooltip)[0];
            return `${date}<span style="color: ${this.color}">\u25CF</span> ${this.series.name}<br/>
            <b>${type}: </b><span style="color: #1cb150; font-weight: bold;">${value}</span>`;
          },
          useHTML: true
        },
        legend: {
          padding: 20,
          maxHeight: 90
        },
        responsive: {
          rules: [{
            condition: {
              maxWidth: 600
            },
            chartOptions: {
              legend: {
                maxHeight: 55
              }
            }
          }]
        },
        exporting: {
          filename: 'iv-graph',
          buttons: {
            contextButton: {
              menuItems: timeDependentButtons
            }
          }
        }
      }
    };
  },
  computed: {
    localOptions() {
      return {
        modules: (this.options && this.options.modules) || [],
        sensors: (this.options && this.options.sensors) || [],
        moduleParameters: (this.options && this.options.moduleParameters) || [],
        sensorParameters: (this.options && this.options.sensorParameters) || [],
        from: (this.options && this.options.from) || null,
        to: (this.options && this.options.to) || null,
        seriesOptions: (this.options && this.options.seriesOptions) || {}
      };
    },
    isEmpty() {
      const { modules, sensors, moduleParameters, sensorParameters, from, to } = this.localOptions;
      if (!from || !to) return true;
      return (!modules.length || !moduleParameters.length) && (!sensors.length || !sensorParameters.length);
    }
  },
  methods: {
    showLoading() {
      if (this.$refs.graph) this.$refs.graph.chart.showLoading();
      this.$emit('loading', true);
    },
    hideLoading() {
      if (this.$refs.graph) this.$refs.graph.chart.hideLoading();
      this.$emit('loading', false);
    },
    async plot() {
      if (this.isEmpty) {
        this.clearPlot();
        return;
      }

      try {
        this.showLoading();
        const { from, to } = this.localOptions;
        const fromIso = moment.tz(moment(from).startOf('day').format('YYYY-MM-DD HH:mm:ss'), this.site.timezone).toISOString(true);
        const toIso = moment.tz(moment(to).endOf('day').format('YYYY-MM-DD HH:mm:ss'), this.site.timezone).toISOString(true);
        const series = (await Promise.all([...this.getModuleSeries(fromIso, toIso), ...this.getSensorSeries(fromIso, toIso)])).flat();
        const seriesWithData = series.filter(s => s && s.data.length);
        if (seriesWithData.length) {
          this.setupYaxis(seriesWithData);
          this.$set(this.highchartOptions, 'series', seriesWithData);
        } else {
          this.$toastWarn('No Data', 'No data is available for the selected duration.');
        }
      } catch (e) {
        if (e.name === 'ApiError') {
          if (e.status === 400 && e.responseBody.details) {
            const module = this.localOptions.modules.find(m => m.uuid === e.request.options.query.moduleUuid);
            const message = `Module ${module.label}: ${e.message}. ${e.responseBody.details.join('. ')}`;
            this.$toastError(`Error ${e.status || ''}`, message);
          } else {
            this.$toastError(`Error ${e.status || ''}`, e.message);
          }
        } else {
          throw e;
        }
      } finally {
        this.hideLoading();
        // Calling redraw redisplays the no data label when the graph is originally empty
        if (this.$refs.graph && this.$refs.graph.chart && !this.highchartOptions.series.length) this.$refs.graph.chart.redraw();
      }
    },
    clearPlot() {
      this.$set(this.highchartOptions, 'series', []);
      this.$set(this.highchartOptions, 'yAxis', []);
    },
    getModuleSeries(from, to) {
      const { modules, moduleParameters, sensors } = this.localOptions;
      const ivParameters = moduleParameters.filter(p => p.type === 'IV').map(p => p.name);
      const ivStcParameters = moduleParameters.filter(p => p.type === 'IV STC').map(p => p.name.replace(/ STC$/g, ''));
      const [operationalEnergyParameter] = moduleParameters.filter(p => p.type === 'Operational Energy');
      const [isolatedEnergyParameter] = moduleParameters.filter(p => p.type === 'Isolated Energy');
      const irradianceSensors = sensors.filter(sensor => Object.keys(sensor.dataTypes).includes('Irr'));

      const moduleRequests = modules.map(async (module) => {
        const requests = [];
        const query = { moduleUuid: module.uuid, from, to };
        if (ivParameters.length) {
          requests.push(this.getIvSeries(module, { ...query, parameters: ivParameters }));
        }
        if (ivStcParameters.length && irradianceSensors.length) {
          requests.push(...irradianceSensors.map((sensor) => {
            return this.getIvStcSeries(module, sensor, { ...query, sensorUuid: sensor.uuid, parameters: ivStcParameters });
          }));
        }
        if (operationalEnergyParameter) {
          requests.push(this.getEnergySeries(module, 'Operational Energy', { ...query, energyType: 'operational' }));
        }
        if (isolatedEnergyParameter) {
          requests.push(this.getEnergySeries(module, 'Isolated Energy', { ...query, energyType: 'isolated' }));
        }

        const series = await Promise.all(requests);
        return series.flat();
      });

      return moduleRequests.flat();
    },
    getSensorSeries(from, to) {
      const { sensors, sensorParameters } = this.localOptions;
      const sensorRequests = sensors.map(async (sensor) => {
        const parameters = sensorParameters.filter(p => p.sensorUuid === sensor.uuid);
        if (!parameters.length) return null;
        const query = { sensorUuid: sensor.uuid, from, to, parameters: parameters.map(p => p.key) };
        const series = await this.getSensorDataSeries(sensor, parameters, query);
        return series.flat();
      });

      return sensorRequests.flat();
    },
    async getIvSeries(module, query) {
      const data = await this.$daqApi.get(`/sites/${this.site.id}/iv/parameters`, { query });
      const extractedData = extractIVData(data);
      return Object.keys(extractedData).map((type) => {
        const id = `${module.uuid}_${type}`;
        const seriesOptions = this.localOptions.seriesOptions[id] || {};
        const name = `Module ${module.name} - ${type}`;
        const color = this.getColor(id);
        const selection = { id, moduleUuid: module.uuid };
        const metaData = { type, unit: mapToUnit(type), selection };
        return { id, name, type: 'line', color, data: extractedData[type], zIndex: 2, metaData, ...seriesOptions };
      });
    },
    async getIvStcSeries(module, sensor, query) {
      const data = await this.$daqApi.get(`/sites/${this.site.id}/iv-stc/parameters`, { query });
      const extractedData = extractIVData(data);
      return Object.keys(extractedData).map((type) => {
        const stcType = `${type} STC`;
        const id = `${module.uuid}_${sensor.uuid}_${stcType}`;
        const seriesOptions = this.localOptions.seriesOptions[id] || {};
        const name = `Module ${module.name} - ${sensor.name} - ${stcType}`;
        const color = this.getColor(id);
        const selection = { id, moduleUuid: module.uuid, sensorUuid: sensor.uuid };
        const metaData = { type: stcType, unit: mapToUnit(stcType), selection };
        return { id, name, type: 'line', color, data: extractedData[type], zIndex: 2, metaData, ...seriesOptions };
      });
    },
    async getEnergySeries(module, type, query) {
      const data = await this.$daqApi.get(`/sites/${this.site.id}/energy`, { query });
      const extractedData = extractEnergyData(data)[module.uuid] || [];
      const id = `${module.uuid}_${type}`;
      const seriesOptions = this.localOptions.seriesOptions[id] || {};
      const name = `Module ${module.name} - ${type}`;
      const color = this.getColor(id);
      return { id, name, type: 'column', color, data: extractedData, zIndex: 0, metaData: { type }, ...seriesOptions };
    },
    async getSensorDataSeries(sensor, parameters, query) {
      const data = await this.$daqApi.get(`/sites/${this.site.id}/sensor-data`, { query });
      const extractedData = extractSensorData(data);
      return Object.keys(extractedData).map((type) => {
        const parameter = parameters.find(p => p.key === type);
        const id = `${sensor.uuid}_${type}`;
        const seriesOptions = this.localOptions.seriesOptions[id] || {};
        const name = `${sensor.name} - ${parameter.name}`;
        const color = this.getColor(id);
        const metaData = { type: parameter.name, unit: parameter.unit, parameter };
        if (type === 'Irr') metaData.selectionAssociation = { name, unit: parameter.unit };
        return { id, name, type: 'line', color, data: extractedData[type], zIndex: 1, metaData, ...seriesOptions };
      });
    },
    setupYaxis(series) {
      const yAxisMap = {};
      const yAxis = [];
      series.forEach((s) => {
        const axisType = s.metaData.parameter ? s.metaData.parameter.unit : mapTypeToAxes(s.metaData.type);
        if (yAxisMap[axisType] == null) {
          yAxisMap[axisType] = yAxis.length;
          const axis = s.metaData.parameter ?
            { labels: { format: `{value}${s.metaData.unit}` }, title: { text: '' }, gridZIndex: -1 } : mapTypeToAxesData(axisType);
          if (yAxis.length % 2 === 1) yAxis.push({ ...axis, opposite: true });
          else yAxis.push(axis);
        }

        s.yAxis = yAxisMap[axisType];
      });

      this.$set(this.highchartOptions, 'yAxis', yAxis);
    },
    getColor(id) {
      let index = this.colorCache.indexOf(id);
      if (index < 0) index = this.colorCache.push(id) - 1;
      return colors[index % colors.length];
    }
  },
  watch: {
    site: {
      immediate: true,
      handler() {
        if (this.site) this.$set(this.highchartOptions, 'time', { timezone: this.site.timezone });
      }
    },
    localOptions() {
      this.plot();
    }
  }
};
</script>

<style lang="scss" scoped>
.highcharts-container {
  min-height: 0;
  min-width: 0;
}
</style>
