<template>
  <multiselect
    :value="value"
    @input="onValueChange"
    :options="filteredValues"
    :selectLabel="selectLabel"
    :selectedLabel="selectedLabel"
    :deselectLabel="deselectLabel"
    :placeholder="placeholder"
    :track-by="trackBy"
    :label="label"
    :custom-label="customLabel"
    :group-label="groupLabel"
    :group-values="groupValues"
    :group-select="groupSelect"
    :selectGroupLabel="selectGroupLabel"
    :deselectGroupLabel="deselectGroupLabel"
    :multiple="multiple"
    :internal-search="false"
    @search-change="searchFunction"
    :closeOnSelect="closeOnSelect"
    :limit="limit"
    :disabled="disabled" >
    <template slot="noOptions">
      <slot name="noOptions"></slot>
    </template>
    <template slot="option" slot-scope="props">
      <slot name="option" :props="props"></slot>
    </template>
  </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  name: 'BetterSearchMultiselect',
  components: {
    Multiselect
  },
  props: {
    value: [Object, Array, Number, String],
    options: Array,
    selectLabel: String,
    selectedLabel: String,
    deselectLabel: String,
    placeholder: String,
    trackBy: String,
    label: String,
    customLabel: Function,
    groupLabel: String,
    groupValues: String,
    groupSelect: Boolean,
    selectGroupLabel: String,
    deselectGroupLabel: String,
    multiple: Boolean,
    closeOnSelect: Boolean,
    limit: Number,
    disabled: Boolean,
  },
  data() {
    return {
      filteredValues: this.options,
    };
  },
  computed: {
    searchFunction() {
      return this.groupLabel ? this.searchFunctionGroup : this.searchFunctionDefault;
    }
  },
  methods: {
    onValueChange(value) {
      this.$emit('input', value);
    },
    searchFunctionDefault(query) {
      const parts = query.split(/ +/g).map(s => s.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'));
      const regex = RegExp(parts.join('.*'), 'i');
      this.filteredValues = this.options.filter((value) => {
        if (!this.label && !this.customLabel) return value.match(regex);
        if (this.customLabel) return this.customLabel(value).match(regex);
        return value[this.label].match(regex);
      });
    },
    searchFunctionGroup(query) {
      const parts = query.split(/ +/g).map(s => s.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'));
      const regex = RegExp(parts.join('.*'), 'i');
      this.filteredValues = this.options.reduce((acc, _group) => {
        const group = _group[this.groupLabel];
        const options = _group[this.groupValues];
        if (group.match(regex)) {
          return acc.concat({ [this.groupLabel]: group, [this.groupValues]: options });
        }

        const filteredOptions = options.filter((value) => {
          if (!this.label && !this.customLabel) return value.match(regex);
          if (this.customLabel) return this.customLabel(value).match(regex);
          return value[this.label].match(regex);
        });

        if (filteredOptions.length) return acc.concat({ [this.groupLabel]: group, [this.groupValues]: filteredOptions });
        return acc;
      }, []);
    },
  },
  watch: {
    options: {
      immediate: true,
      handler(options) {
        if (!options || !options.length) return;
        this.searchFunction('');
      }
    }
  }
};
</script>
