<template>
  <b-modal
    :id="modalId"
    title="Filter"
    ref="modal"
    @ok="onSubmit"
    @cancel="clearForm"
    >
    <div>
      <b-form-group label-for="data-filter-sensor" label="Reference Sensor">
        <multiselect
          id="data-filter-sensor"
          v-model="selectedSensor"
          :options="sensorOptions"
          selectLabel=""
          track-by="uuid"
          label="name"
          placeholder="Select reference sensor"
        />
      </b-form-group>
      <b-form-group label-for="data-filter-type" label="Reference Data">
        <multiselect
          id="data-filter-type"
          v-model="selectedDataType"
          :options="sensorDataOptions"
          selectLabel=""
          track-by="key"
          label="name"
          placeholder="Select reference data"
        >
          <template slot="noOptions">
            Please select a valid sensor.
          </template>
        </multiselect>
      </b-form-group>
      <b-form-group label-for="data-filter-range" label="Filter Range">
        <div id="data-filter-range" class="d-flex justify-content-evenly">
          <b-input-group :append="unit" class="mx-1" >
            <b-form-input type="number" placeholder="Min" v-model="min" :max="max"/>
          </b-input-group>
          <b-input-group :append="unit" class="mx-1" >
            <b-form-input type="number" placeholder="Max" v-model="max" :min="min"/>
          </b-input-group>
        </div>
      </b-form-group>
    </div>
    <template v-slot:modal-footer="{ ok, cancel }">
      <b-button variant="secondary" aria-label="clear" @click="cancel()">Clear</b-button>
      <b-button variant="primary" aria-label="apply" @click="ok()" :disabled="!validForm">Apply</b-button>
    </template>
  </b-modal>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { BModal, BFormGroup, BFormInput, BInputGroup, BButton } from 'bootstrap-vue';

export default {
  name: 'DataFilter',
  components: {
    Multiselect,
    BModal,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BButton
  },
  props: {
    modalId: {
      type: String,
    },
    sensorOptions: {
      type: Array,
    },
  },
  data() {
    return {
      selectedSensor: this.sensorOptions.length ? this.sensorOptions[0] : null,
      selectedDataType: null,
      min: '',
      max: '',
    };
  },
  computed: {
    sensorDataOptions() {
      if (!this.selectedSensor || !this.selectedSensor.dataTypes) return [];
      return Object.entries(this.selectedSensor.dataTypes).map(([key, data]) => ({
        key,
        ...data
      }));
    },
    unit() {
      if (!this.selectedDataType) return null;
      return this.selectedDataType.unit;
    },
    validForm() {
      if (!this.selectedSensor) return false;
      if (!this.selectedDataType) return false;
      if (this.min === '' && this.max === '') return false;
      if (this.min !== '' && this.max !== '' && +this.max < +this.min) return false;
      return true;
    },
  },
  methods: {
    onSubmit() {
      const filter = {
        sensor: this.selectedSensor,
        dataType: this.selectedDataType.key,
        min: this.min !== '' ? Number.parseFloat(this.min) : null,
        max: this.max !== '' ? Number.parseFloat(this.max) : null,
      };
      this.$emit('submit', filter);
    },
    clearForm() {
      this.selectedSensor = null;
      this.selectedDataType = null;
      this.min = '';
      this.max = '';
      this.$emit('submit', null);
    }
  }
};
</script>
