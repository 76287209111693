<template>
<b-container fluid class="analysis-container m-0 px-2 px-md-3 pt-2 pt-md-3 d-flex flex-column">
  <div class="flex-grow-1 d-flex flex-column pb-2 pb-md-3 min-height-fit">
    <b-card no-body class="m-0 p-3 flex-grow-1 d-flex flex-column min-height-fit">
      <b-tabs class="flex-grow-1 d-flex flex-column" no-fade v-model="currentTab">
        <b-tab v-for="(tab, index) in tabs" :key="tab.name" class="p-0">
          <template slot="title">
            <font-awesome-icon icon="times" class="tab-icon" @click.prevent="handleRemoveTab(index)" />
            {{ tab.name }}
          </template>

          <div class="flex-grow-1 d-flex flex-column">
            <component :is="tab.type"></component>
          </div>
        </b-tab>

        <template slot="tabs-end">
          <b-dropdown no-caret variant="link" :disabled="loadingSites">
            <template slot="button-content">
              <font-awesome-icon icon="plus" />
            </template>

            <b-dropdown-item href="#" @click="handleAddNewTab('IvGraph')">IV</b-dropdown-item>
            <b-dropdown-item href="#" @click="handleAddNewTab('ControlGraph')">Control Comparison</b-dropdown-item>
            <b-dropdown-item href="#" @click="handleAddNewTab('WarrantyGraph')">STC Translated</b-dropdown-item>
          </b-dropdown>
        </template>

        <div slot="empty" class="text-center text-muted">
          There are no graphs to display.<br>
          Add a new graph using the <b>+</b> button above.
        </div>
      </b-tabs>
    </b-card>
  </div>
</b-container>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { get } from 'vuex-pathify';
import { BContainer, BCard, BTabs, BTab, BDropdown, BDropdownItem } from 'bootstrap-vue';

import IvGraph from '../components/graphs/iv/IvGraph.vue';
import ControlGraph from '../components/graphs/control/ControlGraph.vue';
import WarrantyGraph from '../components/graphs/WarrantyGraph.vue';

export default {
  name: 'Analysis',
  components: {
    Multiselect,
    IvGraph,
    ControlGraph,
    WarrantyGraph,
    BContainer,
    BCard,
    BTabs,
    BTab,
    BDropdown,
    BDropdownItem
  },
  data() {
    return {
      tabs: [],
      power: 0,
      control: 0,
      warranty: 0,
      currentTab: -1
    };
  },
  computed: {
    selectedSite: get('sites/selectedSite'),
    loadingSites: get('sites/loadingSites')
  },
  methods: {
    handleAddNewTab(type) {
      if (type === 'IvGraph') {
        this.tabs.push({ type, name: `IV ${++this.power}` });
      } else if (type === 'ControlGraph') {
        this.tabs.push({ type, name: `Control Comparison ${++this.control}` });
      } else {
        this.tabs.push({ type, name: `STC Translated ${++this.warranty}` });
      }

      this.$gtagPlugin.events.Analysis.new_graph({ event_label: type });
      setTimeout(() => {
        this.currentTab = this.tabs.length - 1;
      });
    },
    handleRemoveTab(index) {
      this.tabs.splice(index, 1);
    }
  },
  activated() {
    const { graph } = this.$route.query;
    if (graph) this.handleAddNewTab(graph);
  },
  watch: {
    selectedSite: {
      immediate: true,
      handler() {
        this.tabs = [];
        this.power = 0;
        this.control = 0;
        this.warranty = 0;
        this.currentTab = -1;
      }
    },
    tabs: {
      immediate: true,
      handler() {
        if (this.tabs.length) this.$store.commit('sites/SET_SELECTED_SITE_ACTIVE_ANALYSIS', true);
        else this.$store.commit('sites/SET_SELECTED_SITE_ACTIVE_ANALYSIS', false);
      }
    }
  }
};
</script>

<style lang="scss">
.analysis-container {
  height: calc(100vh - 105px)
}

.analysis-container .tab-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: fit-content;
}

.analysis-container .tab-pane.active {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: fit-content;
}

.tab-icon {
  color: #b3b3b3;
  margin-right: 14.5px;
}

.tab-icon:hover {
  color: #7e7d7d;
}

.min-height-fit {
  min-height: fit-content;
}
</style>
