import moment from 'moment-timezone';
import { getFilterZones } from '../helpers';

export default {
  props: {
    site: Object,
    filter: Object
  },
  data() {
    return {
      highchartOptions: {
        plotOptions: {
          series: {
            zoneAxis: 'x'
          }
        }
      }
    };
  },
  computed: {
    localFilter() {
      return {
        sensor: this.filter && this.filter.sensor,
        parameter: this.filter && this.filter.dataType,
        min: this.filter && this.filter.min != null ? this.filter.min : null,
        max: this.filter && this.filter.max != null ? this.filter.max : null,
        from: this.filter && this.filter.from,
        to: this.filter && this.filter.to
      };
    }
  },
  methods: {
    async applyFilter() {
      const { sensor, parameter, min, max, from, to } = this.localFilter;
      if (!sensor || !parameter || !min || !max || !from || !to) {
        this.$set(this.highchartOptions.plotOptions.series, 'zones', null);
        return;
      }

      try {
        const fromIso = moment.tz(moment(from).startOf('day').format('YYYY-MM-DD HH:mm:ss'), this.site.timezone).toISOString(true);
        const toIso = moment.tz(moment(to).endOf('day').format('YYYY-MM-DD HH:mm:ss'), this.site.timezone).toISOString(true);
        const query = { sensorUuid: sensor.uuid, from: fromIso, to: toIso, parameters: parameter };
        const sensorData = await this.$daqApi.get(`/sites/${this.site.id}/sensor-data`, { query });
        if (sensorData.length) {
          const zones = getFilterZones(sensorData, min, max);
          if (zones.length) zones.push({ value: (new Date(toIso)).valueOf(), color: '#D8D8D8' });
          this.$set(this.highchartOptions.plotOptions.series, 'zones', zones);
        } else {
          this.$toastWarn('No Data', 'No sensor data for selected date range.');
        }
      } catch (e) {
        if (e.name === 'ApiError') this.$toastError(`Error ${e.status || ''}`, e.message);
        else throw e;
      }
    }
  },
  watch: {
    filter() {
      this.applyFilter();
    }
  }
};
