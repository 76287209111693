<template>
  <b-dropdown :size="size" :variant="variant" :text="value">
    <b-dropdown-header v-if="header"><strong>{{ header }}</strong></b-dropdown-header>
    <template v-for="(option, index) in options">
      <b-dropdown-item-button
        :key="index"
        @click="handleClick">
        {{ option }}
      </b-dropdown-item-button>
    </template>
  </b-dropdown>
</template>

<script>
import { BDropdown, BDropdownItemButton, BDropdownHeader } from 'bootstrap-vue';

export default {
  name: 'StatefulDropdown',
  components: {
    BDropdown,
    BDropdownItemButton,
    BDropdownHeader
  },
  props: {
    value: String,
    options: Array,
    header: String,
    size: {
      type: String,
      default: 'sm'
    },
    variant: {
      type: String,
      default: 'primary'
    }
  },
  methods: {
    handleClick(e) {
      this.$emit('input', e.target.innerText);
    }
  },
  mounted() {
    this.$emit('input', this.options[0]);
  }
};
</script>
