<template>
  <div class="flex-grow-1 d-flex flex-column px-1 px-md-2 pt-2 pb-2">
    <b-row class="m-0" no-gutters>
      <b-col cols="12" md="6" lg="6" xl="2" class="px-1 pt-2 pt-md-0">
        <better-search-multiselect
          v-model="selectedSolarModule"
          :options="solarModuleOptions"
          selectLabel=""
          deselectLabel="Remove"
          placeholder="Module"
          track-by="uuid"
          label="name"
          group-label="group"
          group-values="modules"
          selectGroupLabel=""
          deselectGroupLabel=""
          :closeOnSelect="true"
          :disabled="requestInProgress">
            <template slot="option" slot-scope="{props}">
              <div class="option__desc">
                <span v-if="props.option.$groupLabel" class="option__title">
                  {{ props.option.$groupLabel }}
                </span>
                <span class="option__title">
                  {{ props.option.name }}
                  <font-awesome-icon
                    v-if="props.option.linkedDaq"
                    v-b-tooltip="`Module linked to ${props.option.linkedDaq}`"
                    icon="link" class="ml-2" />
                </span>
              </div>
            </template>
        </better-search-multiselect>
      </b-col>

      <b-col cols="12" md="6" lg="6" xl="2" class="px-1 pt-2 pt-md-0">
        <better-search-multiselect
          v-model="selectedSensor"
          :options="sensorOptions"
          selectLabel=""
          deselectLabel="Remove"
          placeholder="Sensor"
          track-by="uuid"
          label="name"
          :closeOnSelect="true"
          :disabled="requestInProgress" />
      </b-col>

      <b-col cols="12" md="6" lg="6" xl="2" class="px-1 pt-2 pt-xl-0">
        <better-search-multiselect
          v-model="selectedComparisonParameter"
          :options="comparisonParameterOptions"
          selectLabel=""
          deselectLabel="Remove"
          placeholder="Comparison Parameter"
          track-by="name"
          label="name"
          :closeOnSelect="true"
          :disabled="requestInProgress">

          <template slot="option" slot-scope="{props}">
              <div class="option__desc">
                <span class="option__title">
                  {{ props.option.name }}
                  <font-awesome-icon
                    class="warranty-warning ml-2"
                    v-if="props.option.$isDisabled"
                    v-b-tooltip="{ title: props.option.warning, boundary: 'viewport' }"
                    icon="exclamation-triangle" />
                </span>
              </div>
            </template>
        </better-search-multiselect>
      </b-col>

      <b-col cols="12" md="6" lg="6" xl="2" class="px-1 pt-2 pt-xl-0">
        <flat-pickr
          class="msi-date"
          v-model="from"
          placeholder="Start Date"
          :config="fromDateConfig"
          :disabled="requestInProgress">
        </flat-pickr>
      </b-col>

      <b-col cols="12" md="6" lg="6" xl="2" class="px-1 pt-2 pt-xl-0">
        <flat-pickr
          class="msi-date"
          v-model="to"
          placeholder="End Date"
          :config="toDateConfig"
          :disabled="requestInProgress">
        </flat-pickr>
      </b-col>

      <b-col cols="12" md="12" xl="2" class="px-1 d-flex align-items-center pt-2 pt-xl-0">
        <b-button variant="primary" aria-label="plot" @click="handlePlot" class="mx-1" size="sm" :disabled="addDisabled">Plot</b-button>
        <b-button variant="danger" aria-label="clear" @click="handleClearPlot" class="mx-1" size="sm" :disabled="requestInProgress">Clear</b-button>
        <msi-spinner class="ml-1 no-select" :size="30" v-if="requestInProgress" />
      </b-col>
    </b-row>

    <b-alert
      class="mt-2"
      v-model="disclaimerAlert"
      variant="warning"
      dismissible
      fade>
      <b>Disclaimer:</b> Module performance falling below the warranty line does not necessarily mean a breach of warranty.
      Module performance is subject to losses such as soiling and localized shading, which must be separately accounted for.
    </b-alert>

    <div class="graph-container flex-grow-1 d-flex flex-column pt-3">
      <b-progress
        :value="requestProgress.percent"
        class="mx-2 mb-3"
        height="4px"
        v-if="requestInProgress && requestProgress.visible" />

      <high-charts-responsive class="highcharts-chart-container" :options="powerGraph" ref="powerGraph" />
      <high-charts-responsive class="highcharts-chart-container" :options="comparisonGraph" ref="comparisonGraph" />
    </div>
  </div>
</template>

<script>
/* eslint-disable import/no-named-default */
/* eslint-disable prefer-destructuring */
import moment from 'moment-timezone';
import { get } from 'vuex-pathify';
import flatPickr from 'vue-flatpickr-component';
import { BRow, BCol, BButton, BProgress, BAlert } from 'bootstrap-vue';

import { default as HighChartsResponsive, timeDependentButtons } from '../HighChartsResponsive.vue';
import BetterSearchMultiselect from '../BetterSearchMultiselect.vue';
import MsiSpinner from '../MsiSpinner.vue';

function extractWarranty(data) {
  if (!Array.isArray(data)) return {};
  return data.reduce((acc, cur) => {
    cur.timestamp = (new Date(cur.timestamp)).getTime();
    if (acc.stcPmp) acc.stcPmp.push([cur.timestamp, cur.stcPmp]);
    else acc.stcPmp = [[cur.timestamp, cur.stcPmp]];

    if (acc.comparisonPmp) acc.comparisonPmp.push([cur.timestamp, cur.comparisonPmp]);
    else acc.comparisonPmp = [[cur.timestamp, cur.comparisonPmp]];
    return acc;
  }, {});
}

export default {
  name: 'WarrantyGraph',
  components: {
    BetterSearchMultiselect,
    HighChartsResponsive,
    MsiSpinner,
    flatPickr,
    BRow,
    BCol,
    BButton,
    BProgress,
    BAlert
  },
  data() {
    const component = this;
    const resetZoomStopPropagation = e => e.stopPropagation();
    return {
      selectedSolarModule: null,
      selectedSensor: null,
      selectedComparisonParameter: null,
      from: null,
      to: null,
      now: null,
      requestInProgress: false,
      requestProgress: { visible: false, percent: 0 },
      disclaimerAlert: true,
      powerGraph: {
        chart: {
          zoomType: 'x',
          events: {
            redraw: () => {
              const { chart } = component.$refs.powerGraph;
              if (chart && chart.resetZoomButton) {
                chart.resetZoomButton.element.addEventListener('click', resetZoomStopPropagation);
              }
            }
          }
        },
        legend: {
          padding: 20,
          maxHeight: 90
        },
        title: {
          text: ''
        },
        xAxis: {
          type: 'datetime',
          title: {
            text: 'Time'
          },
          showEmpty: false,
          crosshair: true,
          events: {
            afterSetExtremes({ min, max }) {
              if (!component.$refs.comparisonGraph) return;
              const { chart: comparisonChart } = component.$refs.comparisonGraph;
              comparisonChart.xAxis[0].setExtremes(min, max);
            }
          }
        },
        yAxis: {
          title: {
            text: 'Power'
          },
          showEmpty: false,
        },
        series: [],
        plotOptions: {
          series: {
            states: {
              inactive: {
                opacity: 1
              }
            },
            point: {
              events: {
                mouseOver(e) {
                  if (!e) return;
                  const { chart: comparisonChart } = component.$refs.comparisonGraph;
                  const event = comparisonChart.pointer.getChartCoordinatesFromPoint(this, false);
                  comparisonChart.series.forEach((s) => {
                    const point = s.searchPoint(event, true);
                    if (point && point.series.visible) {
                      point.setState('hover');
                      comparisonChart.xAxis[0].drawCrosshair(event, point);
                    }
                  });
                },
                mouseOut(e) {
                  if (!e) return;
                  const { chart: comparisonChart } = component.$refs.comparisonGraph;
                  const event = comparisonChart.pointer.getChartCoordinatesFromPoint(this, false);
                  comparisonChart.series.forEach((s) => {
                    const point = s.searchPoint(event, true);
                    if (point) {
                      point.setState();
                      comparisonChart.xAxis[0].hideCrosshair();
                      comparisonChart.tooltip.hide();
                    }
                  });
                }
              }
            }
          },
        },
        responsive: {
          rules: [{
            condition: {
              maxWidth: 600
            },
            chartOptions: {
              legend: {
                maxHeight: 55
              },
              yAxis: { title: { text: '' } }
            }
          }]
        },
        tooltip: {
          valueDecimals: 2,
          valueSuffix: ' W',
          shared: true
        },
        exporting: {
          buttons: {
            contextButton: {
              menuItems: timeDependentButtons
            }
          },
          filename: 'daily-performance'
        }
      },
      comparisonGraph: {
        title: {
          text: ''
        },
        xAxis: {
          type: 'datetime',
          title: {
            text: 'Time'
          },
          showEmpty: false,
          crosshair: true,
        },
        yAxis: {
          title: {
            text: 'Pmp Difference'
          },
          labels: {
            format: '{value}%'
          },
          showEmpty: false,
          plotLines: []
        },
        plotOptions: {
          series: {
            states: {
              inactive: {
                opacity: 1
              }
            },
            animation: false,
            lineWidth: 1,
            point: {
              events: {
                mouseOver(e) {
                  if (!e) return;
                  const { chart: powerChart } = component.$refs.powerGraph;
                  const event = powerChart.pointer.getChartCoordinatesFromPoint(this, false);
                  powerChart.series.forEach((s) => {
                    const point = s.searchPoint(event, true);
                    if (point && point.series.visible) {
                      point.setState('hover');
                      powerChart.xAxis[0].drawCrosshair(event, point);
                    }
                  });
                },
                mouseOut(e) {
                  if (!e) return;
                  const { chart: powerChart } = component.$refs.powerGraph;
                  const event = powerChart.pointer.getChartCoordinatesFromPoint(this, false);
                  powerChart.series.forEach((s) => {
                    const point = s.searchPoint(event, true);
                    if (point) {
                      point.setState();
                      powerChart.xAxis[0].hideCrosshair();
                      powerChart.tooltip.hide();
                    }
                  });
                }
              }
            }
          }
        },
        series: [],
        tooltip: {
          valueDecimals: 2,
          valueSuffix: ' %'
        },
        legend: {
          enabled: false
        },
        responsive: {
          rules: [{
            condition: {
              maxWidth: 600
            },
            chartOptions: {
              legend: {
                maxHeight: 55
              },
              yAxis: {
                title: {
                  text: ''
                }
              }
            }
          }]
        },
        exporting: {
          buttons: {
            contextButton: {
              menuItems: timeDependentButtons
            }
          },
          filename: 'daily-performance-difference'
        }
      }
    };
  },
  computed: {
    selectedSite: get('sites/selectedSite'),
    getSolarModuleByUuid: get('solarmodules/getSolarModuleByUuid'),
    getSiteSolarModules: get('solarmodules/getSiteSolarModules'),
    getSiteSensors: get('sensors/getSiteSensors'),
    getSiteGroupedModules: get('groups/getSiteGroupedModules'),
    solarModuleOptions() {
      if (!this.selectedSite) return [];
      return this.getSiteGroupedModules(this.selectedSite.id).map(({ group, modules }) => ({ group: group.name, modules }));
    },
    sensorOptions() {
      if (!this.selectedSite) return [];
      return this.getSiteSensors(this.selectedSite.id).filter(s => Object.keys(s.dataTypes || {}).includes('Irr'));
    },
    comparisonParameterOptions() {
      if (!this.selectedSolarModule) return [];
      if (this.selectedSolarModule.warrantyStartDate && this.selectedSolarModule.moduleDesign.warranty) {
        return [{ name: 'Warranty Pmp', value: 'warrantyPmp' }, { name: 'Nameplate STC Pmp', value: 'nameplateStcPmp' }];
      }

      let warning = '';
      if (!this.selectedSolarModule.warrantyStartDate) warning += 'The warranty start date is missing from the module configuration. ';
      if (!this.selectedSolarModule.moduleDesign.warranty) warning += 'The warranty information is missing from the module design configuration.';
      return [{ name: 'Warranty Pmp', value: 'warrantyPmp', warning, $isDisabled: true }, { name: 'Nameplate STC Pmp', value: 'nameplateStcPmp' }];
    },
    addDisabled() {
      if (!this.selectedSolarModule || !this.selectedSensor || !this.selectedComparisonParameter
        || !this.from || !this.to || this.requestInProgress) return true;
      return false;
    },
    fromDateConfig() {
      return {
        maxDate: this.now,
        dateFormat: 'Y-m-d',
        disableMobile: true,
        allowInput: true
      };
    },
    toDateConfig() {
      return {
        minDate: this.from,
        maxDate: this.now,
        dateFormat: 'Y-m-d',
        disableMobile: true,
        allowInput: true
      };
    }
  },
  methods: {
    async handlePlot() {
      try {
        this.requestInProgress = true;
        if (this.$refs.powerGraph) this.$refs.powerGraph.chart.showLoading();
        if (this.$refs.comparisonGraph) this.$refs.comparisonGraph.chart.showLoading();

        const from = moment.tz(moment(this.from).startOf('day').format('YYYY-MM-DD HH:mm:ss'), this.selectedSite.timezone).toISOString(true);
        const to = moment.tz(moment(this.to).endOf('day').format('YYYY-MM-DD HH:mm:ss'), this.selectedSite.timezone).toISOString(true);
        const comparisonParameter = this.selectedComparisonParameter.value;
        const query = { moduleUuid: this.selectedSolarModule.uuid, sensorUuid: this.selectedSensor.uuid, from, to, comparisonParameter };
        const data = await this.getWarrantyData(query);
        if (data.length === 0) {
          this.$toastWarn('No Data', 'No data is available for the selected module in the selected duration.');
          return;
        }

        const warrantyData = extractWarranty(data);
        const { stcPmp, comparisonPmp } = warrantyData;
        const solarModule = this.getSolarModuleByUuid(query.moduleUuid);

        const series = [];
        series.push({
          id: `${solarModule.name}:Warranty:${from}:${to}`,
          name: `${solarModule.name} ${comparisonParameter === 'warrantyPmp' ? '(Warranty Pmp)' : '(Nameplate STC Pmp)'}`,
          data: comparisonPmp,
          lineWidth: 2,
          dashStyle: 'Dash',
          color: '#2f7ed8',
          unit: 'W',
          isDegradation: true
        });

        series.push({
          id: `${solarModule.name}:STC:${from}:${to}`,
          name: `${solarModule.name} (STC Pmp)`,
          data: stcPmp,
          lineWidth: 2,
          dashStyle: 'Solid',
          color: '#ff6961',
          unit: 'W'
        });

        this.$set(this.powerGraph, 'series', series);
        this.handlePlotDifference(comparisonParameter);
      } catch (e) {
        if (e.name === 'ApiError') this.$toastError(`Error ${e.status || ''}`, `${e.message}. ${e.responseBody.details.join(' ')}`);
        else throw e;
      } finally {
        this.requestInProgress = false;
        if (this.$refs.powerGraph) this.$refs.powerGraph.chart.hideLoading();
        if (this.$refs.comparisonGraph) this.$refs.comparisonGraph.chart.hideLoading();
      }
    },
    async getWarrantyData(query) {
      if (!global.fetch || global.fetch.polyfill !== true) {
        const start = new Date(query.from);
        const end = new Date(query.to);
        const total = end.valueOf() - start.valueOf();
        this.requestProgress = { visible: true, percent: 0 };

        const data = [];
        const jsonStream = await this.$daqApi.requestStream('GET', `/sites/${this.selectedSite.id}/iv-stc/daily-performance`, { query });

        return new Promise((resolve, reject) => {
          jsonStream.on('data', (d) => {
            const timestamp = new Date(d.timestamp);
            this.requestProgress.percent = ((timestamp.valueOf() - start.valueOf()) / total) * 100;
            data.push(d);
          });

          jsonStream.on('end', () => {
            this.requestProgress = { visible: true, percent: 0 };
            resolve(data);
          });

          jsonStream.on('error', reject);
        });
      }

      return this.$daqApi.get(`/sites/${this.selectedSite.id}/iv-stc/daily-performance`, { query });
    },
    handleClearPlot() {
      this.$set(this.powerGraph, 'series', []);
      this.$set(this.comparisonGraph, 'series', []);
    },
    handlePlotDifference(comparisonParameter) {
      const pmpStc = this.powerGraph.series.find(({ isDegradation }) => !isDegradation);
      const pmpStcDegradation = this.powerGraph.series.find(({ isDegradation }) => isDegradation);
      const diff = pmpStc.data.map((d, i) => {
        let x;
        let yPmpStc;
        if (Array.isArray(d)) {
          x = d[0];
          yPmpStc = d[1];
        } else {
          x = d.x;
          yPmpStc = d.y;
        }

        let yPmpStcDegradation;
        const degradationData = pmpStcDegradation.data[i];
        if (Array.isArray(degradationData)) yPmpStcDegradation = degradationData[1];
        else yPmpStcDegradation = degradationData.y;

        return [x, (100 * (yPmpStc - yPmpStcDegradation) / yPmpStcDegradation)];
      });

      this.$set(this.comparisonGraph, 'series', [{
        name: 'Pmp Difference',
        data: diff,
        unit: '%',
        lineWidth: 2,
        color: '#6600FF'
      }]);

      if (comparisonParameter === 'warrantyPmp') {
        this.$set(this.comparisonGraph.yAxis, 'plotLines', [
          {
            color: '#1cb150',
            width: 3,
            value: 0,
            dashStyle: 'Dash',
            label: { text: 'At Warranty', y: 15 }
          }
        ]);
      } else {
        this.$set(this.comparisonGraph.yAxis, 'plotLines', []);
      }
    },
  },
  watch: {
    selectedSite: {
      immediate: true,
      handler(site) {
        if (site && site.timezone) {
          this.$set(this.powerGraph, 'time', { timezone: site.timezone });
          this.$set(this.comparisonGraph, 'time', { timezone: site.timezone });
          this.now = moment().tz(site.timezone).format('YYYY-MM-DD');
          this.selectedSolarModule = null;
          this.selectedSensor = null;
          this.selectedComparisonParameter = null;
          this.from = null;
          this.to = null;
        }
      }
    },
    selectedSolarModule() {
      this.selectedComparisonParameter = null;
    }
  }
};
</script>

<style lang="scss" scoped>
.graph-container {
  flex-basis: 0;
  min-height: 600px;
}

.highcharts-chart-container {
  min-height: 0;
  min-width: 0;
  flex-grow: 0 !important;
  flex-basis: 50%;
}

.warranty-warning {
  pointer-events: all;
  cursor: pointer;
}
</style>

<style lang="scss">
.multiselect__tags {
  min-height: 43px;
  padding-top: 10px;
}

.multiselect__single {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
