<template>
  <div class="flex-grow-1 d-flex flex-column min-height-fit px-1 px-md-2 pt-2 pb-2">
    <b-row class="m-0" no-gutters>
      <b-col cols="12" md="4" xl="3" class="px-1">
        <fieldset class="p-1 form-col">
          <legend>Module</legend>
          <better-search-multiselect
            v-model="modules"
            :options="moduleOptions"
            selectLabel=""
            deselectLabel="Remove"
            placeholder="Modules"
            track-by="uuid"
            label="label"
            group-label="group"
            group-values="modules"
            selectGroupLabel=""
            deselectGroupLabel=""
            group-select
            multiple
            :closeOnSelect="false"
            :limit="2"
            :disabled="loading" >
            <template slot="option" slot-scope="{props}">
              <div class="option__desc">
                <span v-if="props.option.$groupLabel" class="option__title">{{ props.option.$groupLabel }}</span>
                <span class="option__title">
                  {{ props.option.label }}
                  <font-awesome-icon
                    v-if="props.option.linkedDaq"
                    v-b-tooltip="`Module linked to ${props.option.linkedDaq}`"
                    icon="link" class="ml-2" />
                </span>
              </div>
            </template>
          </better-search-multiselect>

          <multiselect
            v-model="moduleParameters"
            :options="moduleParameterOptions"
            selectLabel=""
            deselectLabel="Remove"
            placeholder="Parameters"
            multiple
            selectGroupLabel="Select group"
            group-values="options"
            group-label="type"
            :group-select="true"
            :closeOnSelect="false"
            :limit="3"
            :disabled="loading"
            trackBy="name"
            label="name"
            class="mt-2" />
        </fieldset>
      </b-col>

      <b-col cols="12" md="4" xl="3" class="px-1 pt-2 pt-md-0">
        <fieldset class="p-1 form-col">
          <legend>Sensor</legend>
          <better-search-multiselect
            v-model="sensors"
            :options="sensorOptions"
            selectLabel=""
            deselectLabel="Remove"
            placeholder="Sensors"
            track-by="uuid"
            label="name"
            multiple
            :closeOnSelect="false"
            :limit="2"
            :disabled="loading" />

          <multiselect
            v-model="sensorParameters"
            :options="sensorParameterOptions"
            selectLabel=""
            deselectLabel="Remove"
            placeholder="Parameters"
            multiple
            group-values="parameters"
            :group-select="true"
            group-label="sensor"
            :closeOnSelect="false"
            :limit="2"
            :disabled="loading"
            trackBy="trackBy"
            label="name"
            class="mt-2" />
        </fieldset>
      </b-col>

      <b-col cols="12" md="4" xl="3" class="px-1 pt-2 pt-md-0">
        <fieldset class="p-1 form-col">
          <legend>Date</legend>
          <flat-pickr class="msi-date" v-model="from" placeholder="Start Date" :config="fromDateConfig" :disabled="loading" />
          <flat-pickr class="msi-date mt-2" v-model="to" placeholder="End Date" :config="toDateConfig" :disabled="loading" />
        </fieldset>
      </b-col>

      <b-col cols="12" md="12" xl="3" class="pt-2 mt-1 px-1">
        <b-button variant="primary" aria-label="plot" @click="plot" class="mx-1" size="sm" :disabled="plotDisabled">Plot</b-button>
        <b-button variant="danger" aria-label="clear" @click="clear" class="mx-1" size="sm" :disabled="loading">Clear</b-button>
        <msi-spinner class="ml-1 no-select" :size="30" v-if="loading" />

        <span v-if="showPlotStcWarning" class="px-1 mt-2 mr-3 d-flex align-items-baseline">
          <font-awesome-icon icon="exclamation-triangle" class="warn-icon mr-1" />
          <span>You need to select an irradiance sensor to obtain STC translated data.</span>
        </span>
      </b-col>
    </b-row>

    <b-row class="flex-grow-1 pt-3 min-height-fit" no-gutters>
      <b-col cols="12" lg="6" class="d-flex flex-column min-height-fit">
        <div class="graph-container flex-grow-1 flex-shrink-1 d-flex flex-column">
          <iv-parameters-graph
            :site="selectedSite"
            :options="ivParameterGraphOptions"
            :filter="filter"
            :selectionMode="selectionMode"
            :selection="selection"
            @selectionChange="handleSelection"
            @selectionModeChange="handleSelectionMode"
            @loading="handleLoading" />
        </div>
        <div class="d-flex mt-1 pb-2 pb-lg-0">
          <b-button
            variant="primary"
            aria-label="filter"
            v-b-modal="filterModalId"
            size="sm"
            :disabled="loading">
            Filter
          </b-button>

          <div class="d-flex flex-grow-1 justify-content-end">
            <stateful-dropdown
              v-model="selectionMode"
              :options="['Column', 'Point', 'Range', 'Scan']"
              header="Selection Mode" />

            <b-button size="sm" variant="danger" class="ml-2" @click="handleClearSelection">Clear Selection</b-button>
          </div>
        </div>
      </b-col>

      <b-col cols="12" lg="6" class="d-flex flex-column min-height-fit">
        <div class="graph-container flex-grow-1 flex-shrink-1 d-flex flex-column">
          <iv-curves-graph :site="selectedSite" :curves="selection" :maxYAxis="maxYAxis" />
        </div>
        <div>
          <div class="d-flex flex-grow-1 justify-content-end mt-1">
            <stateful-dropdown v-model="maxIscMode" :options="['Dynamic', 'Isc', 'Nameplate Isc']" header="Y-axis Max" />
          </div>
        </div>
      </b-col>
    </b-row>

    <data-filter :modalId="filterModalId" :sensorOptions="sensorOptions" @submit="applyFilter" />
  </div>
</template>

<script>
import { BRow, BCol, BButton } from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import Multiselect from 'vue-multiselect';
import { get } from 'vuex-pathify';
import moment from 'moment-timezone';
import { v1 as uuidv1 } from 'uuid';
import BetterSearchMultiselect from '../../BetterSearchMultiselect.vue';
import MsiSpinner from '../../MsiSpinner.vue';
import IvParametersGraph from './IvParametersGraph.vue';
import IvCurvesGraph from './IvCurvesGraph.vue';
import Filter from '../Filter.vue';
import StatefulDropdown from '../../StatefulDropdown.vue';

export default {
  name: 'IvGraph',
  components: {
    BRow,
    BCol,
    BButton,
    flatPickr,
    Multiselect,
    BetterSearchMultiselect,
    MsiSpinner,
    IvParametersGraph,
    IvCurvesGraph,
    DataFilter: Filter,
    StatefulDropdown
  },
  data() {
    return {
      modules: [],
      sensors: [],
      moduleParameters: [],
      sensorParameters: [],
      from: null,
      to: null,
      now: null,
      loading: false,
      ivParameterGraphOptions: null,
      filterModalId: uuidv1(),
      filter: null,
      selection: [],
      selectionMode: 'Column',
      maxIsc: null,
      maxIscMode: 'Dynamic'
    };
  },
  computed: {
    selectedSite: get('sites/selectedSite'),
    getSiteGroupedModules: get('groups/getSiteGroupedModules'),
    getSiteSensors: get('sensors/getSiteSensors'),
    moduleOptions() {
      if (!this.selectedSite) return [];
      return this.getSiteGroupedModules(this.selectedSite.id).map(({ group, modules }) => {
        return { group: group.name, modules: modules.map(m => ({ ...m, label: m.name, name: this.getFullModuleName(m) })) };
      });
    },
    moduleParameterOptions() {
      const iv = { type: 'IV', options: ['Imp', 'Isc', 'Pmp', 'Temperature', 'Vmp', 'Voc'].map(o => ({ name: o, type: 'IV' })) };
      const operational = { type: 'Operational', options: ['Iop', 'Pop', 'Vop'].map(o => ({ name: o, type: 'IV' })) };
      const stc = { type: 'STC', options: ['Imp STC', 'Pmp STC', 'Vmp STC'].map(o => ({ name: o, type: 'IV STC' })) };
      const energy = { type: 'Energy', options: ['Operational', 'Isolated'].map(o => ({ name: o, type: `${o} Energy` })) };
      return [iv, operational, stc, energy];
    },
    sensorOptions() {
      if (!this.selectedSite) return [];
      return this.getSiteSensors(this.selectedSite.id);
    },
    sensorParameterOptions() {
      if (!this.selectedSite || !this.sensors.length) return [];
      return this.sensors.reduce((acc, sensor) => {
        const parameters = Object.entries(sensor.dataTypes || {}).map(([key, data]) => {
          return { key, ...data, sensorUuid: sensor.uuid, trackBy: `${sensor.uuid}_${key}` };
        });

        acc.push({ sensor: sensor.name, parameters });
        return acc;
      }, []);
    },
    showPlotStcWarning() {
      if (!this.moduleParameters.find(p => p.type === 'IV STC')) return false;
      const irradianceSensors = this.sensors.filter(sensor => Object.keys(sensor.dataTypes || {}).includes('Irr'));
      if (!irradianceSensors.length) return true;
      return false;
    },
    plotDisabled() {
      const { modules, sensors, moduleParameters, sensorParameters, from, to, loading, showPlotStcWarning } = this;
      if (!from || !to || loading || showPlotStcWarning) return true;
      if ((!modules.length || !moduleParameters.length) && (!sensors.length || !sensorParameters.length)) return true;
      return false;
    },
    fromDateConfig() {
      return { maxDate: this.now, dateFormat: 'Y-m-d', disableMobile: true, allowInput: true };
    },
    toDateConfig() {
      return { minDate: this.from, maxDate: this.now, dateFormat: 'Y-m-d', disableMobile: true, allowInput: true };
    },
    maxYAxis() {
      if (this.maxIsc && this.maxIscMode === 'Isc') return this.maxIsc.isc;
      if (this.maxIsc && this.maxIscMode === 'Nameplate Isc') return this.maxIsc.nameplateIsc || 15;
      return null;
    }
  },
  methods: {
    plot() {
      const { modules, sensors, moduleParameters, sensorParameters, from, to } = this;
      this.ivParameterGraphOptions = JSON.parse(JSON.stringify({ modules, sensors, moduleParameters, sensorParameters, from, to }));
      this.getMaxIsc();
    },
    clear() {
      this.modules = [];
      this.sensors = [];
      this.moduleParameters = [];
      this.sensorParameters = [];
      this.from = null;
      this.to = null;
    },
    applyFilter(filter) {
      const { from, to } = this.ivParameterGraphOptions;
      if (!filter) this.filter = null;
      else if (from && to) this.filter = { ...filter, from, to };
    },
    handleLoading(loading) {
      this.loading = loading;
    },
    handleSelection(selection) {
      this.selection = selection;
    },
    handleClearSelection() {
      this.selection = [];
    },
    handleSelectionMode(selectionMode) {
      this.selectionMode = selectionMode;
    },
    async getMaxIsc() {
      if (!this.selectedSite || !this.ivParameterGraphOptions || !this.ivParameterGraphOptions.modules.length) return;

      try {
        const { id: siteCode, timezone } = this.selectedSite;
        const { from, to } = this.ivParameterGraphOptions;
        const modules = this.ivParameterGraphOptions.modules.map(module => module.uuid);
        const fromIso = moment.tz(moment(from).startOf('day').format('YYYY-MM-DD HH:mm:ss'), timezone).toISOString(true);
        const toIso = moment.tz(moment(to).endOf('day').format('YYYY-MM-DD HH:mm:ss'), timezone).toISOString(true);
        this.maxIsc = await this.$daqApi.get(`/sites/${siteCode}/iv/max-isc`, { query: { modules, to: toIso, from: fromIso } });
      } catch (e) {
        if (e.name === 'ApiError') this.$toastError(`Error ${e.status || ''}`, e.message);
        else throw e;
      }
    }
  },
  mounted() {
    if (!this.selectedSite) return;
    const { modules, to, from } = this.$route.query;
    if (!modules || !to || !from) return;
    const type = this.$route.query.type || 'Pmp';
    const queryModules = Array.isArray(modules) ? modules : [modules];
    const moduleOptions = this.moduleOptions.map(m => m.modules).flat();
    const moduleParameterOptions = this.moduleParameterOptions.map(({ options }) => options).flat();
    this.modules = moduleOptions.filter(m => queryModules.includes(m.uuid));
    this.moduleParameters = moduleParameterOptions.filter(o => o.name === type);
    this.from = moment.parseZone(from).format('YYYY-MM-DD');
    const nowMoment = moment.tz(this.now, this.selectedSite.timezone);
    const toMoment = moment.parseZone(to);
    if (toMoment.isAfter(nowMoment)) this.to = nowMoment.format('YYYY-MM-DD');
    else this.to = toMoment.format('YYYY-MM-DD');
    this.plot();
    this.$router.replace({ query: null });
  },
  watch: {
    selectedSite: {
      immediate: true,
      handler(site) {
        if (!site) return;
        this.now = moment().tz(site.timezone).format('YYYY-MM-DD');
        this.clear();
      }
    },
    ivParameterGraphOptions() {
      this.applyFilter(this.filter);
    }
  }
};
</script>

<style lang="scss" scoped>
fieldset.form-col {
  border: thin solid rgb(233, 236, 239);
  border-radius: 5px;
}

fieldset.form-col legend {
  font-size: 1em;
  padding-right: 1px;
  padding-left: 1px;
  width: auto;
  margin: 0;
  color: rgb(0, 0, 0);
}

.warn-icon {
  color: $msi-warning;
}

.graph-container {
  flex-basis: 0;
  min-height: 400px;
}

.min-height-fit {
  min-height: fit-content;
}
</style>
