<template>
  <div class="flex-grow-1 d-flex flex-column min-height-fit px-1 px-md-2 pt-2 pb-2">
    <b-row class="m-0" no-gutters>
      <b-col cols="12" md="6" lg="6" xl="2" class="px-2">
        <better-search-multiselect
          v-model="controlModule"
          :options="moduleOptions"
          selectLabel=""
          selectedLabel=""
          deselectLabel=""
          placeholder="Control Module"
          track-by="uuid"
          label="name"
          group-label="group"
          group-values="modules"
          closeOnSelect
          :disabled="loading"
          class="mt-2 mt-xl-0">
          <template slot="option" slot-scope="{props}">
            <div class="option__desc">
              <span v-if="props.option.$groupLabel" class="option__title">
                {{ props.option.$groupLabel }}
              </span>
              <span class="option__title">
                {{ props.option.name }}
                <font-awesome-icon
                  v-if="props.option.linkedDaq"
                  v-b-tooltip="`Module linked to ${props.option.linkedDaq}`"
                  icon="link" class="ml-2" />
              </span>
            </div>
          </template>
        </better-search-multiselect>
      </b-col>

      <b-col cols="12" md="6" lg="6" xl="2" class="px-2">
        <better-search-multiselect
          v-model="modules"
          :options="moduleOptions"
          selectLabel=""
          selectedLabel=""
          deselectLabel=""
          placeholder="Modules"
          track-by="uuid"
          label="name"
          group-label="group"
          group-values="modules"
          selectGroupLabel=""
          deselectGroupLabel=""
          group-select
          multiple
          :closeOnSelect="false"
          :limit="1"
          :disabled="loading"
          class="mt-2 mt-xl-0" >
            <template slot="option" slot-scope="{props}">
              <div class="option__desc">
                <span v-if="props.option.$groupLabel" class="option__title">
                  {{ props.option.$groupLabel }}
                </span>
                <span class="option__title">
                  {{ props.option.name }}
                  <font-awesome-icon
                    v-if="props.option.linkedDaq"
                    v-b-tooltip="`Module linked to ${props.option.linkedDaq}`"
                    icon="link" class="ml-2" />
                </span>
              </div>
            </template>
        </better-search-multiselect>
      </b-col>

      <b-col cols="12" md="6" lg="6" xl="2" class="px-2">
        <multiselect
          v-model="parameter"
          :options="parameterOptions"
          selectLabel=""
          deselectLabel="Remove"
          placeholder="Parameter"
          track-by="name"
          label="name"
          :disabled="loading"
          class="mt-2 mt-xl-0">
        </multiselect>
      </b-col>

      <b-col cols="12" md="6" lg="6" xl="2" class="px-2">
        <flat-pickr
          class="msi-date mt-2 mt-xl-0"
          v-model="from"
          placeholder="Start Date"
          :config="fromDateConfig"
          :disabled="loading">
        </flat-pickr>
      </b-col>

      <b-col cols="12" md="6" lg="6" xl="2" class="px-2">
        <flat-pickr
          class="msi-date mt-2 mt-xl-0"
          v-model="to"
          placeholder="End Date"
          :config="toDateConfig"
          :disabled="loading">
        </flat-pickr>
      </b-col>

      <b-col cols="auto" class="pt-2 px-2">
        <b-button variant="primary" aria-label="plot" @click="plot" class="mx-1" size="sm" :disabled="plotDisabled">Plot</b-button>
        <b-button variant="danger" aria-label="clear" @click="clear" class="mx-1" size="sm" :disabled="loading">Clear</b-button>
        <msi-spinner class="ml-1 no-select" :size="30" v-if="loading" />
      </b-col>
    </b-row>

    <b-row class="flex-grow-1 pt-3 min-height-fit" no-gutters>
      <b-col cols="12" lg="6" class="d-flex flex-column min-height-fit">
        <div class="graph-container flex-grow-1 flex-shrink-1 d-flex flex-column">
          <control-comparison-graph
            :site="selectedSite"
            :options="controlComparisonOptions"
            selectionMode="Column"
            :selection="selection"
            @selectionChange="handleSelection"
            @loading="handleLoading"
            strictColumnMode />
        </div>
      </b-col>

      <b-col cols="12" lg="6" class="d-flex flex-column min-height-fit">
        <div class="graph-container flex-grow-1 flex-shrink-1 d-flex flex-column">
          <iv-parameters-graph
            :site="selectedSite"
            :options="ivParameterGraphOptions"
            selectionMode="None" />
        </div>
      </b-col>
    </b-row>

    <div class="d-flex align-items-start mt-1">
      <b-button size="sm" variant="danger" class="ml-2" @click="handleClearSelection">Clear Selection</b-button>
    </div>
  </div>
</template>

<script>
/* eslint-disable import/no-named-default */
import Multiselect from 'vue-multiselect';
import { get } from 'vuex-pathify';
import moment from 'moment-timezone';
import flatPickr from 'vue-flatpickr-component';
import { BRow, BCol, BButton } from 'bootstrap-vue';

import BetterSearchMultiselect from '../../BetterSearchMultiselect.vue';
import MsiSpinner from '../../MsiSpinner.vue';
import ControlComparisonGraph from './ControlComparisonGraph.vue';
import IvParametersGraph from '../iv/IvParametersGraph.vue';

export default {
  name: 'ControlGraph',
  components: {
    Multiselect,
    MsiSpinner,
    BetterSearchMultiselect,
    flatPickr,
    BRow,
    BCol,
    BButton,
    ControlComparisonGraph,
    IvParametersGraph
  },
  data() {
    return {
      controlModule: null,
      modules: [],
      parameter: null,
      parameterOptions: [
        { type: 'IV', name: 'Imp' },
        { type: 'IV', name: 'Isc' },
        { type: 'IV', name: 'Pmp' },
        { type: 'IV', name: 'Vmp' },
        { type: 'IV', name: 'Voc' }
      ],
      from: null,
      to: null,
      now: null,
      loading: false,
      controlComparisonOptions: null,
      selection: [],
      ivParameterGraphOptions: null
    };
  },
  computed: {
    selectedSite: get('sites/selectedSite'),
    getSiteSolarModules: get('solarmodules/getSiteSolarModules'),
    getSiteGroupedModules: get('groups/getSiteGroupedModules'),
    moduleOptions() {
      if (!this.selectedSite) return [];
      const controlModules = [];
      const siteModules = this.getSiteSolarModules(this.selectedSite.id);
      siteModules.forEach(m => (m.isControlModule && controlModules.push(m)));
      const groups = this.getSiteGroupedModules(this.selectedSite.id).map(({ group, modules }) => {
        return { group: group.name, modules: modules.filter(m => !m.isControlModule) };
      });

      groups.unshift({ group: 'Control', modules: controlModules });
      return groups;
    },
    plotDisabled() {
      return !this.controlModule || !this.modules.length || !this.parameter || !this.from || !this.to || this.loading;
    },
    fromDateConfig() {
      return { maxDate: this.now, dateFormat: 'Y-m-d', disableMobile: true, allowInput: true };
    },
    toDateConfig() {
      return { minDate: this.from, maxDate: this.now, dateFormat: 'Y-m-d', disableMobile: true, allowInput: true };
    }
  },
  methods: {
    plot() {
      const { controlModule, modules, parameter, from, to } = this;
      this.controlComparisonOptions = JSON.parse(JSON.stringify({ controlModule, modules, parameter, from, to }));
    },
    clear() {
      this.controlModule = null;
      this.modules = [];
      this.parameter = null;
      this.from = null;
      this.to = null;
    },
    handleLoading(loading) {
      this.loading = loading;
    },
    handleSelection(selection) {
      this.selection = selection;
    },
    handleClearSelection() {
      this.selection = [];
    }
  },
  watch: {
    selectedSite: {
      immediate: true,
      handler(site) {
        if (site && site.timezone) {
          this.now = moment().tz(site.timezone).format('YYYY-MM-DD');
          this.clear();
        }
      }
    },
    selection: {
      immediate: true,
      handler() {
        if (!this.selection.length) this.ivParameterGraphOptions = null;
        else {
          const { controlModule, modules, parameter } = this.controlComparisonOptions;
          const selectedModules = modules.filter(m => this.selection.find(s => s.moduleUuid === m.uuid));

          this.ivParameterGraphOptions = {
            modules: [controlModule, ...selectedModules],
            moduleParameters: [parameter],
            from: moment.utc(this.selection[0].x).tz(this.selectedSite.timezone).startOf('day').format('YYYY-MM-DD'),
            to: moment.utc(this.selection[0].x).tz(this.selectedSite.timezone).endOf('day').format('YYYY-MM-DD'),
            seriesOptions: {
              [`${controlModule.uuid}_${parameter.name}`]: { name: `Module ${controlModule.name} - ${parameter.name} (Control)`, color: '#ff6961' },
              ...this.selection.reduce((acc, cur) => {
                acc[`${cur.moduleUuid}_${parameter.name}`] = { color: cur.color };
                return acc;
              }, {})
            }
          };
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.graph-container {
  flex-basis: 0;
  min-height: 400px;
}

.no-select {
  user-select: none;
}

.min-height-fit {
  min-height: fit-content;
}
</style>
