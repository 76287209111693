<template>
  <high-charts-responsive :options="highchartOptions" ref="graph" class="highcharts-container" />
</template>

<script>
/* eslint-disable import/no-named-default */
import moment from 'moment-timezone';
import { get } from 'vuex-pathify';
import { default as HighChartsResponsive, ivButtons } from '../../HighChartsResponsive.vue';
import { extractIVCurves, getIvTooltip, generateRandomColor } from '../helpers';

export default {
  name: 'IvCurvesGraph',
  components: {
    HighChartsResponsive
  },
  props: {
    site: Object,
    curves: Array,
    maxYAxis: Number
  },
  data() {
    const resetZoomStopPropagation = e => e.stopPropagation();
    return {
      highchartOptions: {
        boost: {
          seriesThreshold: 50
        },
        chart: {
          type: 'scatter',
          zoomType: 'xy',
          panning: true,
          panKey: 'shift',
          events: {
            redraw: () => {
              if (this.$refs.graph.chart && this.$refs.graph.chart.resetZoomButton) {
                this.$refs.graph.chart.resetZoomButton.element.addEventListener('click', resetZoomStopPropagation);
              }
            }
          }
        },
        title: {
          text: ''
        },
        xAxis: {
          title: {
            text: 'Voltage'
          },
          labels: {
            format: '{value}V'
          },
          showEmpty: false
        },
        yAxis: {
          title: {
            text: 'Current'
          },
          labels: {
            format: '{value}A'
          },
          showEmpty: false
        },
        plotOptions: {
          series: {
            marker: {
              enabled: true
            },
            states: {
              inactive: {
                opacity: 1
              }
            },
            animation: false,
            lineWidth: 1,
            events: {
              hide() {
                const opPointSeries = this.chart.series.find(s => s.name === 'Operating Points');
                if (!opPointSeries) return;
                const opPoint = opPointSeries.data.find(({ id }) => id === `${this.name} - Op Point`);
                if (opPoint && opPoint.marker) opPoint.marker.enabled = false;
              },
              show() {
                const opPointSeries = this.chart.series.find(s => s.name === 'Operating Points');
                if (!opPointSeries) return;
                const opPoint = opPointSeries.data.find(({ id }) => id === `${this.name} - Op Point`);
                if (opPoint && opPoint.marker) opPoint.marker.enabled = true;
              }
            }
          }
        },
        series: [],
        tooltip: {
          formatter: function formatter() {
            let { metaData, name } = this.series.options;
            if (this.point.options.linkedSeries) ({ metaData, name } = this.point.options.linkedSeries);
            return getIvTooltip(this.series, this.point, metaData, name);
          },
          useHTML: true
        },
        legend: {
          padding: 20,
          maxHeight: 90,
        },
        responsive: {
          rules: [{
            condition: {
              maxWidth: 600
            },
            chartOptions: {
              legend: {
                maxHeight: 55
              },
              yAxis: {
                title: {
                  text: ''
                }
              }
            }
          }]
        },
        exporting: {
          buttons: {
            contextButton: {
              menuItems: ivButtons
            }
          },
          filename: 'iv-curve-graph'
        }
      }
    };
  },
  computed: {
    getSolarModuleByUuid: get('solarmodules/getSolarModuleByUuid'),
    getSensorByUuid: get('sensors/getSensorByUuid'),
    localCurves() {
      const uniqueCurves = [];
      const uniqueCurveLookup = {};
      const curves = this.curves || [];
      curves.forEach((curve) => {
        if (curve.selection) {
          curve.selection.forEach((s) => {
            const key = `${s.moduleUuid}${s.sensorUuid ? `_${s.sensorUuid}` : ''}:${curve.x1}_${curve.x2}`;
            if (!uniqueCurveLookup[key]) {
              uniqueCurveLookup[key] = true;
              uniqueCurves.push({ ...s, x1: curve.x1, x2: curve.x2, associationPoints: curve.associationPoints, abortKey: key });
            }
          });
        } else {
          const key = `${curve.moduleUuid}${curve.sensorUuid ? `_${curve.sensorUuid}` : ''}:${curve.x}`;
          if (!uniqueCurveLookup[key]) {
            uniqueCurveLookup[key] = true;
            uniqueCurves.push({ ...curve, abortKey: key });
          }
        }
      });

      return uniqueCurves;
    }
  },
  methods: {
    showLoading() {
      if (this.$refs.graph) this.$refs.graph.chart.showLoading();
      this.$emit('loading', true);
    },
    hideLoading() {
      if (this.$refs.graph) this.$refs.graph.chart.hideLoading();
      this.$emit('loading', false);
    },
    async plot() {
      if (!this.localCurves.length) {
        this.clearPlot();
        return;
      }

      try {
        this.showLoading();
        if (this.$options.abortController) this.$options.abortController.abort();
        this.$options.abortController = new this.$daqApi.AbortController();
        const abortKey = this.localCurves.map(curve => curve.abortKey).join(',');
        this.$options.abortKey = abortKey;

        const series = (await Promise.all(this.localCurves.map(curve => this.getIvCurve(curve, this.$options.abortController.signal)))).flat();
        if (this.$options.abortKey !== abortKey) return;
        this.$options.abortController = null;
        this.$set(this.highchartOptions, 'series', []);
        this.$nextTick(() => this.$set(this.highchartOptions, 'series', this.addPmpAndPopPoints(series)));
      } catch (e) {
        if (e.name === 'AbortError' || e.message.startsWith('AbortError') || e.message.startsWith('Aborted'));
        else if (e.name === 'ApiError') this.$toastError(`Error ${e.status || ''}`, e.message);
        else throw e;
      } finally {
        this.hideLoading();
      }
    },
    clearPlot() {
      this.$set(this.highchartOptions, 'series', []);
    },
    async getIvCurve(curve, abortSignal) {
      let from;
      let to;
      if (curve.x != null) {
        from = moment.utc(curve.x).tz(this.site.timezone).toISOString(true);
        to = from;
      } else {
        from = moment.utc(curve.x1).tz(this.site.timezone).toISOString(true);
        to = moment.utc(curve.x2).tz(this.site.timezone).toISOString(true);
      }

      const module = this.getSolarModuleByUuid(curve.moduleUuid);
      const sensor = this.getSensorByUuid(curve.sensorUuid);
      const query = { moduleUuid: module.uuid, from, to };
      if (sensor) query.sensorUuid = sensor.uuid;
      const data = await this.$daqApi.getIVCurve(this.site.id, { query, signal: abortSignal }, !!sensor);
      const extractedCurves = extractIVCurves(data);
      this.addAssociationPoints(extractedCurves, curve.associationPoints);
      return extractedCurves.map((extractedCurve) => {
        return {
          id: `${module.uuid}${sensor ? `_${sensor.uuid}` : ''}_${extractedCurve.metaData.x}`,
          name: `Module ${this.getFullModuleName(module)}${sensor ? ` (${sensor.name}) STC` : ''}`,
          data: extractedCurve.ivCurve,
          color: curve.color || generateRandomColor(),
          metaData: extractedCurve.metaData,
          lineWidth: 1
        };
      });
    },
    addPmpAndPopPoints(series) {
      if (series.length >= this.highchartOptions.boost.seriesThreshold) return series;
      const pops = [];
      const newSeries = series.map((s) => {
        const marker = { fillColor: s.color, lineColor: 'black', lineWidth: 3, radius: 6, enabled: true, symbol: 'circle' };
        const { vop, iop, vmp, imp } = s.metaData;
        if (vop && iop) {
          pops.push({
            id: `${s.name} - Op Point`,
            displayName: 'Operating Point',
            color: s.color,
            marker: { ...marker, symbol: 'diamond' },
            linkedSeries: s,
            x: +vop,
            y: +iop,
          });
        }

        const newData = s.data.map(([x, y]) => {
          if (x.toFixed(2) === (+vmp).toFixed(2) && y.toFixed(2) === (+imp).toFixed(2)) return { marker, displayName: 'Max. Power Point', x, y };
          return { x, y };
        });

        return { ...s, data: newData };
      });

      newSeries.push({ name: 'Operating Points', data: pops, type: 'scatter', showInLegend: false, lineWidth: 0 });
      return newSeries;
    },
    addAssociationPoints(curves, associationPoints) {
      curves.forEach((curve) => {
        associationPoints.forEach((association) => {
          if (!curve.metaData.associations) curve.metaData.associations = [{ name: association[0].name }];
          else curve.metaData.associations.push({ name: association[0].name });
        });
      });

      associationPoints.forEach((association, index) => {
        let curveIndex = 0;
        let associationIndex = 0;
        while (curveIndex < curves.length && associationIndex < association.length) {
          const curAssociation = association[associationIndex];
          const nextAssociation = association[associationIndex + 1];
          const curveX = curves[curveIndex].metaData.x;
          if (!nextAssociation || (Math.abs(curAssociation.x - curveX) < Math.abs(nextAssociation.x - curveX))) {
            if (Math.abs(curAssociation.x - curveX) < 6e5) curves[curveIndex].metaData.associations[index] = curAssociation;
            curveIndex++;
          } else {
            associationIndex++;
          }
        }
      });
    },
    updateYAxis() {
      if (this.maxYAxis == null) this.$set(this.highchartOptions.yAxis, 'max', null);
      else this.$set(this.highchartOptions.yAxis, 'max', this.maxYAxis);
      const { series } = this.highchartOptions;
      this.$set(this.highchartOptions, 'series', []);
      this.$nextTick(() => this.$set(this.highchartOptions, 'series', series));
    }
  },
  watch: {
    localCurves: {
      immediate: true,
      handler() {
        this.plot();
      }
    },
    maxYAxis: {
      immediate: true,
      handler() {
        this.updateYAxis();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.highcharts-container {
  min-height: 0;
  min-width: 0;
}
</style>
