import differenceWith from 'lodash/differenceWith';
import { generateRandomColor } from '../helpers';

function getSeriesXValue(series, index) {
  return Array.isArray(series.data[index]) ? series.data[index][0] : series.data[index].x;
}

const SERIES_FILTERS = {
  SELECTION: s => s && s.visible && s.options.metaData.selection,
  ASSOCIATION: s => s && s.visible && s.options.metaData.selectionAssociation,
  VISIBLE: s => s && s.visible
};

const BOOST_THRESHOLD = 5000;
const ASSOC_PADDING = 1;

export default {
  props: {
    selectionMode: {
      type: String,
      validator: mode => ['Column', 'Point', 'Range', 'Scan', 'None'].includes(mode)
    },
    selection: {
      type: Array,
      default: () => []
    },
    strictColumnMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const resetZoomStopPropagation = e => e.stopPropagation();
    return {
      highchartOptions: {
        series: [],
        chart: {
          events: {
            click: (e) => {
              const classList = (e.target.getAttribute('class') || '').split(' ');
              const menuButton = (
                classList.includes('highcharts-button-box') ||
                classList.includes('highcharts-button-symbol') ||
                classList.includes('highcharts-contextmenu')
              );

              if (!menuButton) this.resolveClickEvent(e);
            },
            redraw: () => {
              if (this.$refs.graph && this.$refs.graph.chart && this.$refs.graph.chart.resetZoomButton) {
                this.$refs.graph.chart.resetZoomButton.element.addEventListener('click', resetZoomStopPropagation);
              }

              this.updateSelectionIndicators();
              this.updateRangeObject();
            }
          }
        },
        plotOptions: {
          line: {
            point: {
              events: {
                click: e => this.resolveClickEvent(e),
                mouseOver: e => this.resolveHoverEvent(e)
              }
            },
            boostThreshold: BOOST_THRESHOLD
          }
        },
        xAxis: {
          crosshair: true
        }
      }
    };
  },
  methods: {
    getStrictNearestPoints(e, seriesFilter = SERIES_FILTERS.SELECTION) {
      if (!this.$refs.graph || !this.$refs.graph.chart) return [];
      const { chart } = this.$refs.graph;
      const point = this.getNearestPoint(e, Infinity, seriesFilter);
      return chart.series.filter(seriesFilter).map(s => this.getNearestPointOnSeries(s, point)).filter(p => !!p && p.x === point.x);
    },
    getNearestPoints(e, seriesFilter = SERIES_FILTERS.SELECTION) {
      if (!this.$refs.graph || !this.$refs.graph.chart) return [];
      const { chart } = this.$refs.graph;
      return chart.series.filter(seriesFilter).map(s => chart.pointer.findNearestKDPoint([s], false, e)).filter(p => !!p);
    },
    getNearestPoint(e, maxDistance = 10, seriesFilter = SERIES_FILTERS.SELECTION) {
      if (!this.$refs.graph || !this.$refs.graph.chart) return null;
      const { chart } = this.$refs.graph;
      const nearestPoints = this.getNearestPoints(e, seriesFilter).map((p) => {
        const x = p.series.xAxis.toPixels(p.x, true) + chart.plotLeft;
        const y = p.series.yAxis.toPixels(p.y, true) + chart.plotTop;
        return { point: p, distance: Math.sqrt(((e.chartX - x) ** 2) + ((e.chartY - y) ** 2)) };
      });

      const [nearestPoint] = nearestPoints.sort((p1, p2) => p1.distance - p2.distance);
      if (nearestPoint && nearestPoint.distance < maxDistance) return nearestPoint.point;
      return null;
    },
    getNearestPointOnSeries(series, point) {
      return series.searchPoint({ chartX: point.plotX + point.series.chart.plotLeft, chartY: point.plotY + point.series.chart.plotTop }, true);
    },
    getNearestPointsToPoints(points, seriesFilter = SERIES_FILTERS.ASSOCIATION) {
      if (!this.$refs.graph || !this.$refs.graph.chart) return [];
      return points.map(p => this.$refs.graph.chart.series.filter(seriesFilter).map(s => this.getNearestPointOnSeries(s, p)).filter(po => !!po));
    },
    isPointSelected(point) {
      const { id } = point.series.options.metaData.selection;
      return this.selection.some(s => s.id === id && s.x === point.x && s.y === point.y);
    },
    isSeriesFirstPointSelected(point) {
      const { id } = point.series.options.metaData.selection;
      return this.selection.some(s => s.id === id);
    },
    formatSelectionPoint(point, color, associationPoints = []) {
      if (!color) color = point.series.color;
      const { selection } = point.series.options.metaData;
      return { ...selection, color, x: point.x, y: point.y, associationPoints };
    },
    formatRange(points, associationPoints = []) {
      const { lineOne, lineTwo } = this.$options.rangeObject;
      return [{ selection: points.map(p => p.series.options.metaData.selection), x1: lineOne.x, x2: lineTwo.x, associationPoints }];
    },
    formatAssociationPoints(points) {
      return points.map(p => ([{ ...p.series.options.metaData.selectionAssociation, x: p.x, y: p.y }]));
    },
    formatAssociationPointsRange(pointsFrom, pointsTo) {
      return pointsFrom.map((from, index) => {
        const start = from.index - ASSOC_PADDING >= 0 ? from.index - ASSOC_PADDING : 0;
        const rangePoints = from.series.options.data.slice(start, pointsTo[index].index + ASSOC_PADDING + 1);
        return rangePoints.map(p => ({ ...from.series.options.metaData.selectionAssociation, x: p[0], y: p[1] }));
      });
    },
    resolveClickEvent(e) {
      if (!this.$refs.graph || !this.$refs.graph.chart || !this.$refs.graph.chart.series.filter(SERIES_FILTERS.VISIBLE).length) return;

      if (this.selectionMode === 'Point') {
        const point = this.getNearestPoint(e);
        if (point && !this.isPointSelected(point)) {
          const associationPoints = this.formatAssociationPoints(this.getNearestPointsToPoints([point])[0]);
          const selectedPoint = this.isSeriesFirstPointSelected(point) ?
            this.formatSelectionPoint(point, generateRandomColor(), associationPoints) : this.formatSelectionPoint(point, null, associationPoints);
          this.$emit('selectionChange', [...this.selection, selectedPoint]);
        }
      } else if (this.selectionMode === 'Column') {
        let points;
        if (!this.strictColumnMode) points = this.getNearestPoints(e);
        else points = this.getStrictNearestPoints(e);
        const associationPoints = this.getNearestPointsToPoints(points);
        this.$emit('selectionChange', points.map((p, i) => this.formatSelectionPoint(p, null, this.formatAssociationPoints(associationPoints[i]))));
      } else if (this.selectionMode === 'Scan') {
        this.$emit('selectionModeChange', 'Column');
        const points = this.getNearestPoints(e);
        const associationPoints = this.getNearestPointsToPoints(points);
        this.$emit('selectionChange', points.map((p, i) => this.formatSelectionPoint(p, null, this.formatAssociationPoints(associationPoints[i]))));
      } else if (this.selectionMode === 'Range') {
        const point = this.getNearestPoint(e, Infinity, SERIES_FILTERS.VISIBLE);
        if (!point) return;
        const { x } = point;
        if (!this.$options.rangeObject.lineOne) {
          this.$options.rangeObject.lineOne = { x, el: this.drawRangeLine(x) };
          this.$options.rangeObject.lineOne.associationPoints = this.getNearestPoints(e, SERIES_FILTERS.ASSOCIATION);
        } else if (!this.$options.rangeObject.lineTwo) {
          if (Math.abs(this.$options.rangeObject.lineOne.x - x) > 8.6e7) {
            this.$toastWarn('Invalid Range', 'The range mode is limited to a 24 hour period. Please pick a shorter range.');
          } else {
            this.$options.rangeObject.lineTwo = { x, el: this.drawRangeLine(x) };
            this.$options.rangeObject.lineTwo.associationPoints = this.getNearestPoints(e, SERIES_FILTERS.ASSOCIATION);
            if (x < this.$options.rangeObject.lineOne.x) {
              const temp = this.$options.rangeObject.lineOne;
              this.$options.rangeObject.lineOne = this.$options.rangeObject.lineTwo;
              this.$options.rangeObject.lineTwo = temp;
            }

            this.$options.rangeObject.space = { el: this.drawRangeSpace() };
            const { associationPoints: associationPointsFrom } = this.$options.rangeObject.lineOne;
            const { associationPoints: associationsPointsTo } = this.$options.rangeObject.lineTwo;
            const associationPoints = this.formatAssociationPointsRange(associationPointsFrom, associationsPointsTo);
            this.$emit('selectionChange', this.formatRange(this.getNearestPoints(e), associationPoints));
          }
        } else {
          this.$emit('selectionChange', []);
        }
      }
    },
    resolveHoverEvent(e) {
      if (this.selectionMode !== 'Scan') return;
      if (!this.$refs.graph || !this.$refs.graph.chart || !this.$refs.graph.chart.series.filter(SERIES_FILTERS.VISIBLE).length) return;
      const event = { chartX: e.target.plotX + e.target.series.chart.plotLeft, chartY: e.target.plotY + e.target.series.chart.plotTop };
      const points = this.getNearestPoints(event);
      this.$emit('selectionChange', points.map(p => this.formatSelectionPoint(p)));
    },
    setSelectionIndicators(selectionPoints) {
      this.clearSelectionIndicators();
      if (this.selectionMode === 'Scan') return;
      const indicators = selectionPoints.map(p => ({ ...p, el: this.drawSelectionIndicator(p) }));
      this.$options.selectionIndicators = indicators;
    },
    updateSelectionIndicators() {
      this.$options.selectionIndicators.forEach((indicator) => {
        if (indicator.el) indicator.el.destroy();
        indicator.el = this.drawSelectionIndicator(indicator);
      });
    },
    clearSelectionIndicators() {
      this.$options.selectionIndicators.forEach(i => i.el && i.el.destroy());
      this.$options.selectionIndicators = [];
    },
    updateRangeObject() {
      if (this.$options.rangeObject.lineOne) {
        if (this.$options.rangeObject.lineOne.el) this.$options.rangeObject.lineOne.el.destroy();
        this.$options.rangeObject.lineOne.el = this.drawRangeLine(this.$options.rangeObject.lineOne.x);
      }
      if (this.$options.rangeObject.lineTwo) {
        if (this.$options.rangeObject.lineTwo.el) this.$options.rangeObject.lineTwo.el.destroy();
        this.$options.rangeObject.lineTwo.el = this.drawRangeLine(this.$options.rangeObject.lineTwo.x);
      }
      if (this.$options.rangeObject.space) {
        if (this.$options.rangeObject.space.el) this.$options.rangeObject.space.el.destroy();
        this.$options.rangeObject.space.el = this.drawRangeSpace();
      }
    },
    clearRangeObject() {
      const { rangeObject } = this.$options;
      if (rangeObject.lineOne && rangeObject.lineOne.el) rangeObject.lineOne.el.destroy();
      if (rangeObject.lineTwo && rangeObject.lineTwo.el) rangeObject.lineTwo.el.destroy();
      if (rangeObject.space && rangeObject.space.el) rangeObject.space.el.destroy();
      this.$options.rangeObject = {};
    },
    drawSelectionIndicator(selectionPoint) {
      if (!this.$refs.graph || !this.$refs.graph.chart) return null;
      const series = this.$refs.graph.chart.series.find(s => s.options.metaData.selection && s.options.metaData.selection.id === selectionPoint.id);
      if (series && series.visible && selectionPoint.x >= series.xAxis.min && selectionPoint.x <= series.xAxis.max) {
        const x = series.xAxis.toPixels(selectionPoint.x, true);
        const y = series.yAxis.toPixels(selectionPoint.y, true);
        return this.$refs.graph.chart.renderer
          .circle(x + this.$refs.graph.chart.plotLeft, y + this.$refs.graph.chart.plotTop, 6)
          .attr({ fill: selectionPoint.color || series.color, stroke: 'black', 'stroke-width': 2, zIndex: 5 })
          .add();
      }

      return null;
    },
    drawRangeLine(x) {
      const { chart } = this.$refs.graph;
      return chart.renderer
        .path(['M', chart.xAxis[0].toPixels(x), chart.plotTop, 'V', chart.plotTop + chart.plotHeight])
        .attr({ stroke: '#000000', 'stroke-width': 1, zIndex: 4 })
        .add();
    },
    drawRangeSpace() {
      const { chart } = this.$refs.graph;
      const startPixels = chart.xAxis[0].toPixels(this.$options.rangeObject.lineOne.x);
      const endPixels = chart.xAxis[0].toPixels(this.$options.rangeObject.lineTwo.x);
      if (!startPixels || !endPixels) return null;
      return chart.renderer
        .rect(startPixels, chart.plotTop, endPixels - startPixels, chart.plotHeight)
        .attr({ fill: '#00000027', zIndex: 3 })
        .add();
    },
    correctSelection(removedSeriesIds, xMin, xMax) {
      let { selection } = this;
      if (removedSeriesIds.length) selection = selection.filter(s => !removedSeriesIds.includes(s.id));
      selection = selection.filter(s => s.x >= xMin && s.x <= xMax);
      if (selection.length !== this.selection.length) this.$emit('selectionChange', selection);
    },
    correctRangeSelection(addedSeries, removedSeriesIds, xMin, xMax) {
      let rangeSelection = this.selection[0];
      const { x1, x2 } = rangeSelection;
      if (x1 < xMin || x1 > xMax || x2 < xMin || x2 > xMax) {
        this.$emit('selectionChange', []);
        return;
      }

      if (removedSeriesIds.length) {
        rangeSelection = { ...rangeSelection, selection: rangeSelection.selection.filter(s => !removedSeriesIds.includes(s.id)) };
      }
      if (addedSeries.length) {
        rangeSelection = { ...rangeSelection, selection: rangeSelection.selection.concat(addedSeries.map(s => s.metaData.selection)) };
      }
      if (removedSeriesIds.length || addedSeries.length) this.$emit('selectionChange', [rangeSelection]);
    }
  },
  beforeCreate() {
    this.$options.selectionIndicators = [];
    this.$options.rangeObject = {};
  },
  watch: {
    'highchartOptions.series': {
      immediate: true,
      handler(newSeries, oldSeries) {
        // Determine if we need to force boost
        if (newSeries.some(s => s.data.length >= BOOST_THRESHOLD)) this.$set(this.highchartOptions.plotOptions.line, 'boostThreshold', 1);
        else this.$set(this.highchartOptions.plotOptions.line, 'boostThreshold', BOOST_THRESHOLD);

        if (!this.selection.length) return;
        const seriesWithData = newSeries.filter(s => s.data.length);
        const xMin = Math.min(...seriesWithData.map(s => getSeriesXValue(s, 0)));
        const xMax = Math.max(...seriesWithData.map(s => getSeriesXValue(s, s.data.length - 1)));
        const removedSeriesIds = differenceWith(oldSeries, newSeries, (a, b) => a.id === b.id)
          .filter(s => s.metaData.selection)
          .map(s => s.metaData.selection.id);

        if (this.selectionMode === 'Range') {
          const addedSeries = differenceWith(newSeries, oldSeries, (a, b) => a.id === b.id).filter(s => s.metaData.selection);
          this.correctRangeSelection(addedSeries, removedSeriesIds, xMin, xMax);
        } else {
          this.correctSelection(removedSeriesIds, xMin, xMax);
        }
      }
    },
    selection: {
      immediate: true,
      handler() {
        if (!this.selection.length) this.clearRangeObject();
        this.setSelectionIndicators(this.selection);
      }
    },
    selectionMode: {
      immediate: true,
      handler(cur, prev) {
        if (this.selectionMode === 'Point') this.$set(this.highchartOptions.xAxis, 'crosshair', false);
        else this.$set(this.highchartOptions.xAxis, 'crosshair', true);

        if (cur === 'Column' && prev === 'Scan'); // Clicking in scan mode switches to column mode and we want to keep the selection intact
        else this.$emit('selectionChange', []);
      }
    }
  }
};
